<template>
  <div>

    <div v-if="isValid === true">
      <!-- Title and subtitle -->
      <h1 class="auth-title reset-title">{{ $t('RESET.TITLE') }}</h1>
      <p class="auth-subtitle">{{ $t('RESET.SUBTITLE') }}</p>

      <!-- Form -->
      <!-- TODO: Improve error handling/messages. -->
      <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
        <template #form-content>

          <!-- Password field -->
          <PasswordConfirmation :field-title="`${$t('PASSWORD')}`"
                                :field-title-confirm="`${$t('REPEAT_PASSWORD')}`"
                                :placeholder="$t('PASSWORD')"
                                :placeholder-confirm="$t('REPEAT_PASSWORD')"
                                :input-icon="require('../../../../assets/icons/icn_password.svg')"
                                :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                                :show-error="false"
                                :debounce="0"
                                :is-matching="checkMatching"
                                :custom-text-error-class="'text-error-right'"
                                :api-error-text="authError"
                                :display-api-error="false"
                                class="auth-form-element"/>


          <div v-if="isNewAccount" class="new-account">

            <!-- Terms of use agreement -->
            <Checkbox :field-title="''"
                      :text="$t('REGISTER_TEACHER.AGREE')"
                      :field-name="'agreeToTerms'"
                      :value="false"
                      :rules="'required'">
              <template #additional-text>
                <a class="link-main" :href="promotionalWebsiteUserPolicyUrl" target="_blank">{{ $t('REGISTER_TEACHER.TERMS') }}</a>
              </template>
            </Checkbox>

            <!-- Receive emails/instructions -->
            <!-- <Checkbox
              :field-title="''"
              :text="$t('REGISTER_TEACHER.RECEIVE_EMAILS')"
              :field-name="'receiveEmails'"
              :rules="''"
              :value="true"
              class="auth-check-element"
            /> -->

            <!-- Newsletter -->
            <!-- <Checkbox
              :field-title="''"
              :text="$t('REGISTER_TEACHER.NEWSLETTER')"
              :field-name="'receiveNewsletter'"
              :rules="''"
              :value="false"
              class="auth-check-element"
            /> -->
          </div>

        </template>
        <template #button-submit>
          <button type="submit" class="pink-button auth-button button-reset">
            {{ $t('RESET.BUTTON') }}
          </button>
        </template>
      </FormWrapper>

      <!-- Login prompt. -->
      <AuthPrompt :text="$t('RESET.BACK_TO')"
                  :route-name="ROUTE_NAMES_AUTH.LOGIN"
                  :link-text="$t('RESET.LOGIN')"
                  class="auth-prompt"/>
    </div>
    <div v-else class="empty-state">
      <img class="empty-icon" :src="require('../../../../assets/img/img_noresult@2x.png')"/>
      <h1 class="empty-title"> {{ $t('RESET.EXPIRED_TITLE') }}</h1>
      <div>
        <p class="empty-text"> {{ $t('RESET.EXPIRED_TEXT_1') }}</p>
        <a class="empty-text inline link" title="info@mediamasters.nl" href="mailto:info@mediamasters.nl"> {{ $t('RESET.EXPIRED_TEXT_2') }}</a>
        <p class="empty-text inline"> {{ $t('RESET.EXPIRED_TEXT_3') }}</p>
      </div>
      <BasicButton class="empty-button"
                   :button-text="$t('RESET.EXPIRED_BUTTON')"
                   @click="returnToLogin" />
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import AuthPrompt from '@/components/elements/auth/AuthPrompt'
import FormWrapper from '@/components/yo-form/FormWrapper'
import PasswordConfirmation from '@/components/yo-form/PasswordConfirmation'
import { ref } from '@vue/reactivity'
import Checkbox from '@/components/yo-form/Checkbox'

import { useStore } from 'vuex'
import { FORGET_PASSWORD, VERIFY_PASSWORD_RESET_TOKEN } from '@/store/modules/auth/actions'
import ApiHandler from '../../../../utils/handlers/ApiHandler'
import BasicButton from '@/components/elements/basic/BasicButton'
import { SET_TOAST_DATA } from '@/store/mutations'
import { useI18n } from 'vue-i18n'


export default {
  name: 'ResetPassword',
  components: {
    BasicButton,
    AuthPrompt,
    FormWrapper,
    PasswordConfirmation,
    Checkbox,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const token = route.params.token
    const isMatching = ref(false)

    const isNewAccount = ref(false)
    const isValid = ref(true)
    const isExpired = ref(false)
    const { t } = useI18n()
    const promotionalWebsiteUserPolicyUrl = `${process.env.VUE_APP_PROMOTIONAL_WEBSITE_URL}/${t('ROUTE.PROMOTIONAL_WEBSITE_USER_POLICY')}`

    store.dispatch(VERIFY_PASSWORD_RESET_TOKEN, token).then(response => {
      if (response.status === 204) {
        isValid.value = true
        isNewAccount.value = response.data !== '' && response.data.isNewAccount
      } else {
        isValid.value = false
      }
    })

    function checkMatching(value) {
      isMatching.value = value
    }

    function returnToLogin() {
      router.push({ name: ROUTE_NAMES_AUTH.LOGIN })
    }

    // Form
    function validateData(data) {
      if (!isMatching.value) {
        return false
      }
      //add password resetting.
      store.dispatch(FORGET_PASSWORD, { token: route.params.token, password: data.password }).then(data => {
        if (data) {
          console.log(data)
          store.commit(SET_TOAST_DATA, { src: require('../../../../assets/icons/icn_successful.svg'), text: 'Wachtwoord succesvol gewijzigd' })
          router.push({ name: ROUTE_NAMES_AUTH.LOGIN })
        }
      })
    }

    return {
      ROUTE_NAMES_AUTH,
      authError: '',
      apiErrorText: '', // TODO: Get from the API.

      promotionalWebsiteUserPolicyUrl,

      // Form
      validateData,
      checkMatching,
      isNewAccount,
      isValid,
      returnToLogin,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.reset-title {
  margin-bottom: rem(14);
}

.empty-state{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: rem(100) auto 0 auto;
  max-width: rem(480);
  text-align: center;

  .empty-icon{
    width: rem(128);
    height: rem(128);
    object-fit: contain;
    object-position: bottom;
  }

  .empty-title{
    margin-top: rem(20);
    margin-bottom: rem(17);
  }

  .link{
    color: var(--blue_link);
    font-weight: 600;
  }

  .inline{
    display: inline;
  }

  .empty-button{
    margin-top: rem(30);
  }
}

</style>
