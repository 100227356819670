<template>
  <div>


    <div class="form-group password-confirmation">
      <!-- First password field -->
      <h3 class="form-input-title">{{ fieldTitle }}
        <span v-if="inlineSubtitle" class="inline-subtitle">{{ inlineSubtitle }}</span>

      </h3>
      <label :for="FIELD_NAME_PASSWORD" class="input-field-label">
        <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
        <InlineSvg v-if="inputIcon" class="input-icon" :src="inputIcon" :class="{ 'error': apiError.length > 0 || pErrorMessages[0], 'contains-input': valuePasswordInput.length > 0 }" />
        <input :id="FIELD_NAME_PASSWORD"
               v-model="valuePasswordInput"
               :class="{ 'field-error': apiError || pErrorMessages.length > 0 }"
               :placeholder="placeholder"
               :autocomplete="autoComplete"
               :name="FIELD_NAME_PASSWORD"
               :maxlength="64"
               type="password"
               class="form-input"
               @input="pHandleInput">
        <img v-if="inputIconError && (apiErrorText || pErrorMessages[0])" :src="inputIconError" class="input-error-icon" alt="Input icon"/>
      </label>
      <div v-show="fieldInfo && !apiError && pErrorMessages.length === 0">
        <p class="field-info">{{ fieldInfo }}</p>
      </div>
      <div v-show="apiError || pErrorMessages.length > 0">
        <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
        <p v-if="apiError" class="text-error">{{ apiError }}</p>
        <p v-for="(error, index) of pErrorMessages" v-else :key="index" class="text-error">{{ error }}</p>
      </div>
    </div>
    <div class="form-group password-confirmation">
      <!-- NOTE! Make sure your confirmation field's fieldName is passed here into name prop -->
      <h3 class="form-input-title">{{ fieldTitleConfirm }}</h3>
      <label :for="FIELD_NAME_CONFIRMATION" class="input-field-label">
        <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
        <!-- <img v-if="inputIcon && !apiErrorText" :src="inputIcon" class="input-icon" alt="Input icon"/> -->
        <InlineSvg v-if="inputIcon" class="input-icon" :src="inputIcon" :class="{ 'error': apiError.length > 0 || pErrorMessages[0], 'contains-input': valuePasswordRepeatInput.length > 0 }" />
        <input :id="FIELD_NAME_CONFIRMATION"
               v-model="valuePasswordRepeatInput"
               :class="{ 'field-error': apiError || cErrorMessages.length > 0 }"
               :placeholder="placeholderConfirm"
               :name="FIELD_NAME_CONFIRMATION"
               :maxlength="64"
               type="password"
               autocomplete="new-password"
               class="form-input"
               @input="cHandleInput">
        <img v-if="inputIconError && (apiErrorText || cErrorMessages[0])" :src="inputIconError" class="input-error-icon" alt="Input icon"/>
      </label>
      <div v-show="apiError || cErrorMessages.length > 0">
        <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
        <p v-if="apiError" class="text-error">{{ apiError }}</p>
        <p v-for="(error, index) of cErrorMessages" v-else :key="index" class="text-error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
// import {ref, watch} from '@vue/composition-api';
import Validator from '@/utils/yo-validator/YoValidator'
import InlineSvg from 'vue-inline-svg'

const FIELD_NAME_PASSWORD = 'password'
const FIELD_NAME_CONFIRMATION = 'confirmation'
const RULE_NAME_CONFIRMATION = 'passwordConfirm'
const RULE_NAME_SPECIAL_CHARACTER = 'passwordSpecialCharacter'
const RULE_NAME_LENGTH = 'passwordLength'
const RULE_NAME_LOWERCASE = 'passwordLowercase'
const RULE_NAME_UPPERCASE = 'passwordUppercase'
const RULE_NAME_NUMBER = 'passwordNumber'

export default {
  name: 'PasswordConfirmation',
  components: {
    InlineSvg
  },
  props: {
    fieldTitle: {
      type: String,
      required: true
    },
    fieldTitleConfirm: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    placeholderConfirm: {
      type: String,
      required: true
    },
    debounce: {
      type: Number,
      required: false,
      default: 0
    },
    apiErrorText: {
      required: false,
      type: String,
      default: null
    },
    // These can be used to give an initial value to the input fields.
    valuePassword: {
      type: String,
      required: false,
      default: null
    },
    valueConfirmation: {
      type: String,
      required: false,
      default: null
    },
    inputIcon: {
      type: String,
      required: false,
      default: ''
    },
    inputIconError: {
      type: String,
      required: false,
      default: ''
    },
    fieldInfo: {
      type: String,
      required: false,
      default: ''
    },
    isMatching: {
      type: Function,
      required: false,
      default: () => {}
    },
    autoComplete: {
      type: String,
      default: 'current-password'
    },
    required: {
      type: Boolean,
      default: true
    },
    inlineSubtitle: {
      type: String,
      required: false,
      default: ''
    },
  },
  setup(props) {
    /** validator returned error messages **/
    const { errorMessages: pErrorMessages, handleInput: pHandleInput, setFieldData: pSetFieldData } = Validator.register({
      fieldName: FIELD_NAME_PASSWORD,
      rules: `${props.required ? 'required|' : ''}
      ${RULE_NAME_NUMBER}|${RULE_NAME_SPECIAL_CHARACTER}|${RULE_NAME_LOWERCASE}|${RULE_NAME_UPPERCASE}|${RULE_NAME_LENGTH}`
    })

    const { handleInput: cHandleInput, setFieldData: cSetFieldData } = Validator.register({
      fieldName: FIELD_NAME_CONFIRMATION,
      rules: `${props.required ? 'required|' : ''}${RULE_NAME_CONFIRMATION}:@${FIELD_NAME_CONFIRMATION}`
    })


    const cErrorMessages = ref([])



    /** watch api error text from parent **/
    const apiError = ref('')

    /** on created **/
    const valuePasswordInput = ref('')
    const valuePasswordRepeatInput = ref('')

    function checkMatching(passA, passB) {
      if (passA !== passB) {
        cErrorMessages.value = ['De wachtwoorden komen niet overeen.']
      } else {
        cErrorMessages.value = []
      }
      props.isMatching(passA === passB)
    }

    watch(() => [valuePasswordInput.value, valuePasswordRepeatInput.value], ([newPass, newConfirm], [prevPass, prevConfirm]) => {
      checkMatching(newPass, newConfirm)
    });
    // if(props.value) {
    //   input.value = props.value
    // }
    (function setValueOnCreate() {
      if (props.valuePassword) {
        valuePasswordInput.value = props.valuePassword
        pSetFieldData(props.valuePassword)
      }
      if (props.valueConfirmation) {
        valuePasswordRepeatInput.value = props.valueConfirmation
        cSetFieldData(props.valueConfirmation)
      }
    })()

    watch(() => props.apiErrorText, (newVal) => {
      apiError.value = newVal.trim()
    })

    // watch(() => props.valuePassword, (newVal) => {
    //   valuePasswordInput.value = props.valuePassword
    //   pSetFieldData(props.valuePassword)
    // })
    //
    // watch(() => props.valueConfirmation, (newVal) => {
    //   valuePasswordRepeatInput.value = props.valueConfirmation
    //   pSetFieldData(props.valueConfirmation)
    // })

    return {

      apiError,
      pErrorMessages,
      pHandleInput,
      cErrorMessages,
      cHandleInput,
      FIELD_NAME_PASSWORD,
      FIELD_NAME_CONFIRMATION,
      valuePasswordInput,
      valuePasswordRepeatInput,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.password-confirmation {
  margin-bottom: rem(25);
}


.input-icon {
  :deep g path {
    fill: var(--blue_light_01);
  }
}

.input-icon.contains-input {
  :deep g path {
    fill: var(--blue_dark_01);
  }
}

.input-icon.error {
  :deep g path {
    fill: var(--red_error);
  }
}
</style>
